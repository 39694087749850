import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import { Helmet } from "react-helmet"
import Accordion from '../components/Accordion'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import MultiItemTemplate from '../components/MultiItemTemplate'
import Slide from '../components/Slide'

function STITemplate({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide }
  const { sti } = pageContext

  const items = [
    {text: 'Chlamydia', path: 'chlamydia'},
    {text: 'Gonorrhea', path: 'gonorrhea'},
    {text: 'HPV', path: 'hpv'},
    {text: 'Syphilis', path: 'syphilis'},
    {text: 'Herpes', path: 'herpes'},
    {text: 'HIV', path: 'hiv'},
    {text: 'Trichomoniasis', path: 'trichomoniasis'},
    {text: 'Bacterial Vaginosis', path: 'bacterial-vaginosis'}
  ]

  return (
    <div className="STITemplate">
      <Helmet>
        <title>{sti.frontmatter.title}</title>
        <meta name="description" content={sti.frontmatter.title} />
      </Helmet>
      <MultiItemTemplate
        imageNames={sti.frontmatter.images}
        name={sti.frontmatter.title}
        items={items}
        path="/sex-and-relationships/sexual-health/know-your-stis/stis"
        title="Know your STIs"
        noun="STI"
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {sti.body}
          </MDXRenderer>
        </MDXProvider>
      </MultiItemTemplate>
    </div>
  )
}

export default STITemplate